import React, { useEffect, useMemo, useState } from 'react';

import { preventDefault } from '@neslotech/utils';
import { useFormState } from '@neslotech/hooks';

import { currencyFormat } from '../../tools/calculators';

import FormRow from '../../components/form-row/FormRow';
import Select from '../../components/select/Select';
import Button from '../../components/button/Button';
import PackageSummary from './summary/PackageSummary';

import './pricing-plans.scss';
import Input from '../../components/input/Input';

export const limitedTurnoverItems = [
  {
    label: '£0 - £45 000',
    value: 0
  },
  {
    label: '£46 000 - £80 000',
    value: 1
  },
  {
    label: '£81 000 - £240 000',
    value: 2
  },
  {
    label: '£241 000 - £480 000',
    value: 3
  },
  {
    label: '£481 000 - £1 000 000',
    value: 4
  },
  {
    label: '£1 100 000 - £3 000 000',
    value: 5
  },
  {
    label: '£3 100 000 - £5 000 000',
    value: 6
  }
];

export const limitedPriceBands = [
  [45.0, 89.0, 179.0],
  [75.0, 139.0, 229.0],
  [145.0, 279.0, 379.0],
  [205.0, 389.0, 549.0],
  [325.0, 639.0, 1229.0],
  [445.0, 1440.0, 2265.0],
  [565.0, 1940.0, 2930.0]
];

export const limitedEmployees = {
  base: 0,
  peak: 3,
  summit: 10
};

export const soleTraderTurnoverItems = [
  {
    label: '£0 - £45 000',
    value: 0
  },
  {
    label: '£46 000 - £80 000',
    value: 1
  },
  {
    label: '£81 000 - £240 000',
    value: 2
  },
  {
    label: '£241 000 - £480 000',
    value: 3
  },
  {
    label: '£481 000 - £1 000 000',
    value: 4
  },
  {
    label: '£1 100 000 - £3 000 000',
    value: 5
  },
  {
    label: '£3 100 000 - £5 000 000',
    value: 6
  }
];

export const soleTraderPriceBands = [
  [30.0, 69.0, 149.0],
  [55.0, 109.0, 189.0],
  [125.0, 229.0, 349.0],
  [185.0, 329.0, 509.0],
  [300.0, 589.0, 989.0],
  [435.0, 1320.0, 2120.0],
  [545.0, 1820.0, 2820.0]
];

export const soleTraderEmployees = {
  base: 0,
  peak: 3,
  summit: 10
};

export const propertyTurnoverItems = [
  {
    label: '1 Property',
    value: 0
  },
  {
    label: '2 Properties',
    value: 1
  },
  {
    label: '3 Properties',
    value: 2
  },
  {
    label: '4 Properties',
    value: 3
  },
  {
    label: 'Up to 7 Properties',
    value: 4
  },
  {
    label: 'Up to 15 Properties',
    value: 5
  },
  {
    label: 'Up to 25 Properties',
    value: 6
  }
];

export const propertyPriceBands = [
  [0, 42.0, 75.0],
  [0, 72.0, 129.0],
  [0, 99.0, 169.0],
  [0, 119.0, 229.0],
  [0, 159.0, 379.0],
  [0, 249.0, 529.0],
  [0, 379.0, 779.0]
];

export const propertyEmployees = {
  base: 0,
  peak: 1,
  summit: 3
};

const yesNoItems = [
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  }
];

const rules = {
  validates: {
    turnover: ['isPresent']
  }
};

const PricingPlans = () => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const [summary, setSummary] = useState();
  const [context, setContext] = useState('limited');

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    switch (location.pathname) {
      case '/property-companies-pricing-plans':
        setContext('property');
        break;
      case '/sole-traders-pricing-plans':
        setContext('sole_trader');
        break;
      default:
        setContext('limited');
        break;
    }
  }, []);

  const items = useMemo(() => {
    switch (context) {
      case 'property':
        return propertyTurnoverItems;
      case 'sole_trader':
        return soleTraderTurnoverItems;
      default:
        return limitedTurnoverItems;
    }
  }, [context]);

  const priceBands = useMemo(() => {
    switch (context) {
      case 'property':
        return propertyPriceBands;
      case 'sole_trader':
        return soleTraderPriceBands;
      default:
        return limitedPriceBands;
    }
  }, [context]);

  const employeeLimits = useMemo(() => {
    switch (context) {
      case 'property':
        return propertyEmployees;
      case 'sole_trader':
        return soleTraderEmployees;
      default:
        return limitedEmployees;
    }
  }, [context]);

  const handleSubmit = () => {
    const turnover = Number(form.turnover);
    const prices = priceBands[turnover];

    setSummary({
      context,
      employeeLimits,
      turnoverAmount: items[turnover].label,
      base: currencyFormat(prices[0]),
      peak: currencyFormat(prices[1]),
      summit: currencyFormat(prices[2]),
      vatReturns: form.vatReturns,
      payroll: form.payroll,
      bookkeeping: form.bookkeeping,
      employees: form.employees
    });
  };

  return (
    <article className="pricing-plans">
      <form onSubmit={preventDefault}>
        <FormRow>
          <Select
            name="turnover"
            label="Select Turnover"
            placeholder="Select your answer"
            items={items}
            value={form?.turnover}
            error={form?.messages?.turnover}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Select
            name="vatReturns"
            label="Need Vat Returns Completed?"
            onChange={handleChange}
            value={form?.vatReturns}
            items={yesNoItems}
            error={form?.messages?.vatReturns}
            placeholder="Select your answer"
          />
          <Select
            name="payroll"
            label="Need Payroll Managing?"
            onChange={handleChange}
            value={form?.payroll}
            items={yesNoItems}
            error={form?.messages?.payroll}
            placeholder="Select your answer"
          />
        </FormRow>
        <FormRow>
          <Select
            name="bookkeeping"
            label="Need Bookkeeping?"
            onChange={handleChange}
            value={form?.bookkeeping}
            items={yesNoItems}
            error={form?.messages?.bookkeeping}
            placeholder="Select your answer"
          />
          {form?.payroll === 'yes' && (
            <Input
              min="0"
              type="number"
              name="employees"
              label="Number of Employees?"
              onChange={handleChange}
              value={form?.employees}
              items={yesNoItems}
              error={form?.payroll === 'yes' && !form?.employees ? 'This is required' : undefined}
              placeholder="Enter number of employees"
            />
          )}
        </FormRow>
        <Button label="Calculate" disabled={!form?.valid} onClick={handleSubmit} />
      </form>
      {summary && <PackageSummary summary={summary} />}
    </article>
  );
};

export default PricingPlans;
