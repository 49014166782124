export const calculateAnnualDue = (bands, maxBandIndex, annualTotal) =>
    bands.reduce((accum, band, currentIndex) => {
        if (currentIndex > maxBandIndex) {
            return accum;
        }

        const upperBand = band[0][1];
        const lowerBand = band[0][0];
        const percentage = band[1];

        if (currentIndex === maxBandIndex) {
            return accum + ((annualTotal - lowerBand) * percentage);
        }

        return accum + ((upperBand - lowerBand) * percentage);
    }, 0);

export const findBandIndex = (bands, annualTotal) =>
    bands.findIndex((band) => {
        const lowerBand = band[0][0];
        const upperBand = band[0][1];
        return annualTotal > lowerBand && annualTotal < upperBand;
    });

export const getMultiplier = (frequency) => {
    switch (frequency) {
        case 'weekly':
            return 52;
        case 'monthly':
            return 12;
        default:
            return 1;
    }
};

export const currencyFormat = (value, currency = 'GBP') => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency
    });

    return formatter.format(value);
}

/**
 * Calculates the PMT (payment) for an investment
 *
 * @param {number} rate - The annual interest rate, expressed as a decimal (e.g. 0.05 for 5%)
 * @param {number} term - The total number of payments (e.g. 60 for a 5-year loan)
 * @param {number} loanAmount - The present value of the loan (i.e. the principal)
 * @param {number} [fv=0] - The future value of the investment, defaults to 0 if not provided
 * @param {number} [type=1] - The type of payment, 1 for payments made at the beginning of the period (default), 0 for
 *     payments made at the end of the period
 *
 * @returns {number} The calculated PMT
 */
export const pmt = (rate, term, loanAmount, fv = 0, type = 1) => {
    if (type === 0) {
        return (rate * (fv + loanAmount * (1 + rate) ** term)) / ((1 + rate) ** term - 1);
    } else {
        return (rate * (loanAmount + fv)) / (1 - (1 + rate) ** -term);
    }
}
