import axios from 'axios';

const sender = {
    email: 'no-reply@xenithwealth.com'
};

export const sendEmail = (to, replyTo, body, subject) => {
    return axios.post('https://api.sendinblue.com/v3/smtp/email', {
        sender,
        to,
        replyTo,
        htmlContent: body,
        subject
    }, {
        headers: {
            'accept': 'application/json',
            'content-type': 'application/json',
            'api-key': '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'
        }
    })
};
