import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { isMobile } from '../../tools/responsive.util';

import './modal.scss';

const Modal = ({ children }) => {
  let mount = document.getElementById('modal-root');
  if (!mount || isMobile()) {
    mount = document.getElementById('modal-root-mobile');
  }

  const overlay = document.createElement('section');
  overlay.setAttribute('class', 'modal-overlay');

  const modalEl = document.createElement('section');
  modalEl.setAttribute('class', 'modal');

  const modalContent = document.createElement('section');
  modalContent.setAttribute('class', 'modal-content');

  useEffect(() => {
    mount.appendChild(overlay);
    overlay.appendChild(modalEl);
    modalEl.appendChild(modalContent);

    return () => {
      mount.removeChild(overlay);
    };
  }, [modalEl, mount, overlay, modalContent]);
  return createPortal(children, modalContent);
};

export default Modal;
