import React, { useState } from 'react';

import { useFormState } from '@neslotech/hooks';
import { preventDefault } from '@neslotech/utils';

import {
  calculateAnnualDue,
  currencyFormat,
  findBandIndex,
  getMultiplier
} from '../../tools/calculators';

import FormRow from '../../components/form-row/FormRow';
import Select from '../../components/select/Select';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import Summary from './summary/Summary';

import './salary-calculator.scss';

const rules = {
  validates: {
    year: ['isPresent'],
    frequency: ['isPresent'],
    earnings: ['isPresent', 'isGreaterThanZero'],
    employer: ['isPresent']
  }
};

const yearItems = [
  {
    label: '2022',
    value: '2022'
  },
  {
    label: '2023',
    value: '2023'
  }
];

const frequencyItems = [
  {
    label: 'Weekly',
    value: 'weekly'
  },
  {
    label: 'Monthly',
    value: 'monthly'
  },
  {
    label: 'Yearly',
    value: 'yearly'
  }
];

const employerItems = [
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  }
];

const salaryBands = [
  [[0, 12750.0], 0],
  [[12570.0, 50270.0], 0.2],
  [[50270.0, 162770.0], 0.4],
  [[162770.0, Infinity], 0.45]
];

const insuranceBands = [
  [[0, 11908.0], 0],
  [[11908.0, 50270.0], 0.13],
  [[50270.0, Infinity], 0.03]
];

const employerInsuranceBands = [
  [[0, 9100.0], 0],
  [[9100.0, Infinity], 0.15]
];

const SalaryCalculator = () => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const [summary, setSummary] = useState(null);

  const handleSubmit = () => {
    calculate();
  };

  const calculate = () => {
    const employer = form.employer;
    const multiplier = getMultiplier(form.frequency);

    const annualSalary = Number(form.earnings) * multiplier;
    const monthlySalary = annualSalary / 12;
    const salaryBandIndex = findBandIndex(salaryBands, annualSalary);
    const insuranceBandIndex = findBandIndex(insuranceBands, annualSalary);
    const employerInsuranceBandIndex = findBandIndex(employerInsuranceBands, annualSalary);

    const annualTaxDue = calculateAnnualDue(salaryBands, salaryBandIndex, annualSalary);
    const annualInsuranceDue = calculateAnnualDue(insuranceBands, insuranceBandIndex, annualSalary);
    const annualEmployerInsuranceDue = calculateAnnualDue(
      employerInsuranceBands,
      employerInsuranceBandIndex,
      annualSalary
    );

    const monthlyTaxDue = Math.round((annualTaxDue / 12) * 100) / 100;
    const monthlyInsuranceDue = Math.round((annualInsuranceDue / 12) * 100) / 100;
    const monthlyEmployerInsuranceDue = Math.round((annualEmployerInsuranceDue / 12) * 100) / 100;
    const takeHomePay =
      Math.round((annualSalary / 12 - monthlyTaxDue - monthlyInsuranceDue) * 100) / 100;
    const costToCompany = Math.round((annualSalary / 12 + monthlyEmployerInsuranceDue) * 100) / 100;

    setSummary({
      employer: employer === 'yes',
      monthlySalary: currencyFormat(monthlySalary - monthlyTaxDue),
      annualSalary: currencyFormat(annualSalary),
      monthlyTaxDue: currencyFormat(monthlyTaxDue),
      monthlyInsuranceDue: currencyFormat(monthlyInsuranceDue),
      monthlyEmployerInsuranceDue: currencyFormat(monthlyEmployerInsuranceDue),
      takeHomePay: currencyFormat(takeHomePay),
      costToCompany: currencyFormat(costToCompany)
    });
  };

  return (
    <article className="salary-calculator">
      <form onSubmit={preventDefault}>
        <FormRow>
          <Select
            items={yearItems}
            name="year"
            label="Tax Year"
            placeholder="Select Tax Year"
            value={form?.year}
            error={form?.messages?.year}
            onChange={handleChange}
          />
          <Select
            items={frequencyItems}
            name="frequency"
            label="How often do you get paid?"
            placeholder="Select Your Answer"
            value={form?.frequency}
            error={form?.messages?.frequency}
            onChange={handleChange}
          />
        </FormRow>
        <FormRow>
          <Input
            name="earnings"
            label="How much do you earn before tax"
            placeholder="Enter earnings before tax"
            value={form?.earnings}
            error={form?.messages?.earnings}
            onChange={handleChange}
            prefix="£"
            type="number"
            min="0"
          />
          <Select
            items={employerItems}
            name="employer"
            label="Are you an employer?"
            placeholder="Select Your Answer"
            value={form?.employer}
            error={form?.messages?.employer}
            onChange={handleChange}
          />
        </FormRow>
        <Button
          label={summary ? 'Recalculate' : 'Calculate'}
          disabled={!form?.valid}
          onClick={handleSubmit}
        />
      </form>
      {summary && <Summary summary={summary} />}
    </article>
  );
};

export default SalaryCalculator;
