import React, { createRef } from 'react';

import './select.scss';

const getChangeValue = (name, value) => (name ? { [name]: value } : value);

const Select = ({ label, placeholder, name, value, error, onChange, items }) => {
  const ref = createRef();

  const handleChange = (e) => {
    let val = e.target.value;
    val = getChangeValue(name, val);
    onChange(val);
  };

  return (
    <article className="select">
      {label && (
        <label className="select__label" htmlFor={name}>
          {label}
        </label>
      )}
      <section className="select__wrapper">
        <select
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={handleChange}
          ref={ref}
        >
          <option disabled selected value="">
            {placeholder}
          </option>
          {items.map((item) => (
            <option key={item.label} value={item.value}>
              {item.label}
            </option>
          ))}
        </select>
      </section>
      <section className="select__error">{error && <small>{error}</small>}</section>
    </article>
  );
};

export default Select;
