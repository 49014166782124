import React from 'react';

import './summary.scss';

const Summary = ({ summary }) => (
  <article className="summary">
    <dl>
      <dt>Monthly repayment:</dt>
      <dd>{summary.paymentPerMonth}</dd>
    </dl>
    <dl>
      <dt>Total repaid over period:</dt>
      <dd>{summary.periodRepayment}</dd>
    </dl>
    <dl>
      <dt>Total interest:</dt>
      <dd>{summary.totalInterest}</dd>
    </dl>
    <dl>
      <dt>Total capital:</dt>
      <dd>{summary.totalCapital}</dd>
    </dl>
  </article>
);

export default Summary;
