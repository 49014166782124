import React, { useState } from 'react';

import { capitalise, getClassNames, scrollIntoView } from '@neslotech/utils';

import { sendEmail } from '../../../tools/mailer';
import { currencyFormat } from '../../../tools/calculators';
import { isMobile } from '../../../tools/responsive.util';

import Button from '../../../components/button/Button';
import Modal from '../../../components/modal/Modal';
import ModalLayout from '../../../components/modal/ModalLayout';
import QuoteModal from '../modal/QuoteModal';

import './package-summary.scss';

const PackageSummary = ({ summary, onSubmit }) => {
  const [selectedType, setSelectedType] = useState('peak');
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [packageSelected, setPackageSelected] = useState();

  const isBase = selectedType === 'base';
  const isPeak = selectedType === 'peak';
  const isSummit = selectedType === 'summit';

  const handleSelect = () => {
    if (onSubmit) {
      onSubmit({
        type: capitalise(selectedType),
        cost: isBase ? summary.base : isPeak ? summary.peak : summary.summit
      });
      return;
    }

    setPackageSelected({
      type: capitalise(selectedType),
      cost: isBase ? summary.base : isPeak ? summary.peak : summary.summit
    });
    setShowModal(true);
    scrollIntoView('pricing-heading');
  };

  const handleSubmit = async (payload) => {
    setShowError(false);
    sendEmail(
      [
        {
          name: 'Xenith Wealth',
          email: 'info@xenithwealth.co.uk'
        }
      ],
      { name: payload.fullName ?? payload.business, email: payload.email },
      `
            <html>
        <body>
        <header>
            <h3>Hi Xenith Wealth!</h3>
        </header>
        <main>
            <p>A formal quote has been requested by the following details:</p>
            <br /><br />
            <strong>Full Name: </strong>
            <p>${payload.fullName}</p><br />
            <strong>Business Name: </strong>
            <p>${payload.business}</p><br />
            <strong>Contact Number: </strong>
            <p>${payload.cellphone}</p><br />
            <strong>Email Address: </strong>
            <p>${payload.email}</p><br />
            <strong>Turnover Range: </strong>
            <p>${summary.turnoverAmount}</p><br />
            <strong>Package Cost: </strong>
            <p>${packageSelected.cost}</p><br />
            <strong>Selected Package: </strong>
            <p>${packageSelected.type}</p><br />
            <strong>Company Type: </strong>
            <p>${capitalise(summary.context)}</p><br />
            <strong>Needs Bookkeeping: </strong>
            <p>${summary.bookkeeping ? 'Yes' : 'No'}</p><br />
            <strong>Needs VAT Returns: </strong>
            <p>${summary.vatReturns ? 'Yes' : 'No'}</p><br />
            <strong>Needs Payroll: </strong>
            <p>${summary.payroll ? 'Yes' : 'No'}</p><br />
            <strong>Number of Employees: </strong>
            <p>${summary.payroll ? summary.employees : 'N/A'}</p><br />
        </main>
        </body>
        </html>
            `,
      'Formal Quote Requested | Xenith Wealth Website'
    )
      .then(() => {
        setShowModal(false);
      })
      .catch((error) => {
        setShowError(true);
      });
  };

  return (
    <article className="package-summary">
      <header>
        {summary.context !== 'property' && (
          <article
            onClick={() => setSelectedType('base')}
            className={getClassNames('package-summary__switcher', { selected: isBase })}
          >
            <p>Base</p>
          </article>
        )}
        <article
          onClick={() => setSelectedType('peak')}
          className={getClassNames('package-summary__switcher', { selected: isPeak })}
        >
          <p>Peak</p>
        </article>
        <article
          onClick={() => setSelectedType('summit')}
          className={getClassNames('package-summary__switcher', { selected: isSummit })}
        >
          <p>Summit</p>
        </article>
      </header>
      <main>
        {((isMobile() && isBase) || !isMobile()) && (
          <>
            {summary.context !== 'property' && (
              <section className={getClassNames('package-summary__package', { active: isBase })}>
                <h1>Base</h1>
                <p className="package-summary__price">{summary.base}</p>
                <p className="package-summary__payroll">
                  **Includes Payroll for {summary.employeeLimits.base} employees**
                </p>
                {Number(summary.employees) > summary.employeeLimits.base && (
                  <p className="package-summary__payroll">
                    **Additional{' '}
                    {currencyFormat(
                      (Number(summary.employees) - summary.employeeLimits.base) * 7.5
                    )}{' '}
                    for {Number(summary.employees) - summary.employeeLimits.base} employees**
                  </p>
                )}
                <p className="package-summary__description">
                  Best for small businesses <br />
                  looking to start off
                </p>
                <Button large label="Select Package" disabled={!isBase} onClick={handleSelect} />
              </section>
            )}
          </>
        )}
        {((isMobile() && isPeak) || !isMobile()) && (
          <section className={getClassNames('package-summary__package', { active: isPeak })}>
            <h1>Peak</h1>
            <p className="package-summary__price">{summary.peak}</p>
            <p className="package-summary__payroll">
              **Includes Payroll for {summary.employeeLimits.peak} employees**
            </p>
            {Number(summary.employees) > summary.employeeLimits.peak && (
              <p className="package-summary__payroll">
                **Additional{' '}
                {currencyFormat((Number(summary.employees) - summary.employeeLimits.peak) * 7.5)}{' '}
                for {Number(summary.employees) - summary.employeeLimits.peak} employees**
              </p>
            )}
            <p className="package-summary__description">
              Best for small businesses <br />
              looking to grow
            </p>
            <Button large label="Select Package" disabled={!isPeak} onClick={handleSelect} />
          </section>
        )}
        {((isMobile() && isSummit) || !isMobile()) && (
          <section className={getClassNames('package-summary__package', { active: isSummit })}>
            <h1>Summit</h1>
            <p className="package-summary__price">{summary.summit}</p>
            <p className="package-summary__payroll">
              **Includes Payroll for {summary.employeeLimits.summit} employees**
            </p>
            {Number(summary.employees) > summary.employeeLimits.summit && (
              <p className="package-summary__payroll">
                **Additional{' '}
                {currencyFormat((Number(summary.employees) - summary.employeeLimits.summit) * 7.5)}{' '}
                for {Number(summary.employees) - summary.employeeLimits.summit} employees**
              </p>
            )}
            <p className="package-summary__description">
              Best for small businesses <br />
              looking to exceed
            </p>
            <Button large label="Select Package" disabled={!isSummit} onClick={handleSelect} />
          </section>
        )}
      </main>
      {showModal && (
        <Modal>
          <ModalLayout title="Request Formal Quote" onClose={() => setShowModal(false)}>
            <QuoteModal onSubmit={handleSubmit} error={showError} />
          </ModalLayout>
        </Modal>
      )}
    </article>
  );
};

export default PackageSummary;
