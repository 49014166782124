import React from 'react';

import { preventDefault } from '@neslotech/utils';
import { useFormState } from '@neslotech/hooks';

import FormRow from '../../../components/form-row/FormRow';
import Input from '../../../components/input/Input';
import Button from '../../../components/button/Button';

import './quote-modal.scss';

const rules = {
  validates: {
    business: ['isPresent'],
    cellphone: ['isPresent', 'isNumeric'],
    email: ['isPresent', 'isEmail']
  }
};

const QuoteModal = ({ onSubmit, error }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const handleSubmit = () => {
    onSubmit({
      fullName: form.fullName,
      business: form.business,
      cellphone: form.cellphone,
      email: form.email
    });
  };

  return (
    <article className="quote-modal">
      <header>
        <p className="quote-modal__message">
          Enter your details below and a formal quote will be sent to you
        </p>
        <p>**All fields marked with an asterisk are compulsory**</p>
      </header>
      <form onSubmit={preventDefault}>
        <FormRow>
          <Input
            name="fullName"
            label="Full Name"
            onChange={handleChange}
            value={form?.fullName}
            placeholder="Enter your full name"
          />
          <Input
            name="business"
            label="Business Name*"
            onChange={handleChange}
            value={form?.business}
            placeholder="Enter your business name"
            error={form?.messages?.business}
          />
        </FormRow>
        <FormRow>
          <Input
            name="cellphone"
            label="Cellphone number*"
            onChange={handleChange}
            value={form?.cellphone}
            error={form?.messages?.cellphone}
            placeholder="Enter your cell number"
            prefix="+44"
          />
          <Input
            name="email"
            label="Email Address*"
            onChange={handleChange}
            value={form?.email}
            placeholder="Enter your email address"
            error={form?.messages?.email}
          />
        </FormRow>
        <Button label="Get Quote" disabled={!form?.valid} onClick={handleSubmit} />
        {error && <p style={{ color: 'red' }}>We were unable to send your email at this time.</p>}
      </form>
    </article>
  );
};

export default QuoteModal;
