import React from 'react';

import './input.scss';

const renderError = (error) => error && <small className="input__error">{error}</small>;

const getChangeValue = (name, value) => (name ? { [name]: value } : value);

const Input = ({
  label,
  placeholder,
  name,
  type = 'text',
  value,
  error,
  onChange,
  prefix,
  min
}) => {
  const handleChange = (e) => {
    let val = e.target.value;
    val = getChangeValue(name, val);
    onChange(val);
  };

  return (
    <article className="input">
      <label className="input__label">{label}</label>
      <section className="input__wrapper">
        {prefix && (
          <section className="input__prefix">
            <label>{prefix}</label>
          </section>
        )}
        <input
          id={name}
          onChange={handleChange}
          type={type}
          name={name}
          value={value}
          min={min}
          placeholder={placeholder}
        />
      </section>
      {renderError(error)}
    </article>
  );
};

export default Input;
