import React, { useMemo } from 'react';

import { preventDefault } from '@neslotech/utils';
import { useFormState } from '@neslotech/hooks';

import FormRow from '../../../components/form-row/FormRow';
import Button from '../../../components/button/Button';
import Select from '../../../components/select/Select';
import Input from '../../../components/input/Input';
import {
  limitedTurnoverItems,
  propertyTurnoverItems,
  soleTraderTurnoverItems
} from '../../pricing-plans/PricingPlans';

import './instant-quote-modal.scss';

const rules = {
  validates: {
    companyType: ['isPresent'],
    turnover: ['isPresent'],
    vatReturns: ['isPresent'],
    payroll: ['isPresent'],
    bookkeeping: ['isPresent']
  }
};

const companyTypes = [
  {
    label: 'Start-up/LTD/LLP/Partnership',
    value: 'limited'
  },
  {
    label: 'Sole Trader',
    value: 'sole_trader'
  },
  {
    label: 'Property',
    value: 'property'
  }
];

const yesNoItems = [
  {
    label: 'Yes',
    value: 'yes'
  },
  {
    label: 'No',
    value: 'no'
  }
];

const InstantQuoteModal = ({ onSubmit }) => {
  const [form, setForm] = useFormState(undefined, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const turnoverRanges = useMemo(() => {
    switch (form?.companyType) {
      case 'sole_trader':
        return soleTraderTurnoverItems;
      case 'property':
        return propertyTurnoverItems;
      case 'limited':
        return limitedTurnoverItems;
      default:
        return [];
    }
  }, [form?.companyType]);

  const handleSubmit = () => {
    onSubmit({
      turnoverAmount: turnoverRanges[form.turnover].label,
      companyType: form.companyType,
      turnover: form.turnover,
      vatReturns: form.vatReturns,
      payroll: form.payroll,
      bookkeeping: form.bookkeeping,
      employees: form.employees
    });
  };

  return (
    <article className="instant-quote-modal">
      <header>
        <p className="instant-quote-modal__message">
          We charge a monthly fee based on your business type and turnover
        </p>
        <p>**All prices exclude VAT**</p>
      </header>
      <form onSubmit={preventDefault}>
        <FormRow>
          <Select
            name="companyType"
            label="Select Business Type"
            onChange={handleChange}
            value={form?.companyType}
            error={form?.messages?.companyType}
            items={companyTypes}
            placeholder="Select Your Business Type"
          />
          <Select
            name="turnover"
            label="Select Turnover"
            onChange={handleChange}
            value={form?.turnover}
            items={turnoverRanges}
            placeholder="Select Turnover"
            error={form?.messages?.turnover}
          />
        </FormRow>
        <FormRow>
          <Select
            name="vatReturns"
            label="Need Vat Returns Completed?"
            onChange={handleChange}
            value={form?.vatReturns}
            items={yesNoItems}
            error={form?.messages?.vatReturns}
            placeholder="Select your answer"
          />
          <Select
            name="payroll"
            label="Need Payroll Managing?"
            onChange={handleChange}
            value={form?.payroll}
            items={yesNoItems}
            error={form?.messages?.payroll}
            placeholder="Select your answer"
          />
        </FormRow>
        <FormRow>
          <Select
            name="bookkeeping"
            label="Need Bookkeeping?"
            onChange={handleChange}
            value={form?.bookkeeping}
            items={yesNoItems}
            error={form?.messages?.bookkeeping}
            placeholder="Select your answer"
          />
          {form?.payroll === 'yes' && (
            <Input
              min="0"
              type="number"
              name="employees"
              label="Number of Employees?"
              onChange={handleChange}
              value={form?.employees}
              items={yesNoItems}
              error={form?.payroll === 'yes' && !form?.employees ? 'This is required' : undefined}
              placeholder="Enter number of employees"
            />
          )}
        </FormRow>
        <Button label="Get Quote" disabled={!form?.valid} onClick={handleSubmit} />
      </form>
    </article>
  );
};

export default InstantQuoteModal;
