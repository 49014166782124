import React, { useState } from 'react';

import { useFormState } from '@neslotech/hooks';
import { preventDefault } from '@neslotech/utils';

import { currencyFormat, pmt } from '../../tools/calculators';

import FormRow from '../../components/form-row/FormRow';
import Input from '../../components/input/Input';
import Button from '../../components/button/Button';
import Summary from './summary/Summary';

import './vehicle-calculator.scss';

const rules = {
  validates: {
    cost: ['isPresent', 'isGreaterThanZero'],
    deposit: ['isNotNegative'],
    term: ['isPresent', 'isGreaterThanZero'],
    balloonPayment: ['isNotNegative'],
    rate: ['isPresent', 'isGreaterThanZero']
  }
};

const VehicleCalculator = () => {
  const [form, setForm] = useFormState({ deposit: 0, balloonPayment: 0 }, rules);
  const handleChange = (newState) => setForm({ ...form, ...newState });

  const [summary, setSummary] = useState(null);

  const handleSubmit = () => {
    calculate();
  };

  const calculate = () => {
    const presentValue = Number(form.cost) - Number(form.deposit);
    const futureValue = Number(form.cost) * (Number(form.balloonPayment) / 100);
    // convert percentage to monthly amount
    const rate = Number(form.rate) / 100 / 12;
    const term = Number(form.term);

    const paymentPerMonth = pmt(rate, term, presentValue, futureValue, 0);
    const periodRepayment = paymentPerMonth * term;
    const totalInterest = periodRepayment - presentValue;

    setSummary({
      paymentPerMonth: currencyFormat(paymentPerMonth),
      periodRepayment: currencyFormat(periodRepayment),
      totalInterest: currencyFormat(totalInterest),
      totalCapital: currencyFormat(presentValue)
    });
  };

  return (
    <article className="vehicle-calculator">
      <form onSubmit={preventDefault}>
        <FormRow>
          <Input
            name="cost"
            label="Vehicle price"
            placeholder="Enter vehicle price"
            value={form?.cost}
            error={form?.messages?.cost}
            onChange={handleChange}
            prefix="£"
            type="number"
            min="0"
          />
          <Input
            name="deposit"
            label="Down payment"
            placeholder="Enter your deposit amount"
            value={form?.deposit}
            error={form?.messages?.deposit}
            onChange={handleChange}
            prefix="£"
            type="number"
            min="0"
          />
        </FormRow>
        <FormRow>
          <Input
            name="term"
            label="Loan term (months)"
            placeholder="Enter the loan term in months"
            value={form?.term}
            error={form?.messages?.term}
            onChange={handleChange}
            type="number"
            min="0"
          />
          <Input
            name="rate"
            label="Interest Rate (%)"
            placeholder="Enter the interest rate as a percentage"
            value={form?.rate}
            error={form?.messages?.rate}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </FormRow>
        <FormRow>
          <Input
            name="balloonPayment"
            label="Balloon Payment (%)"
            placeholder="Enter balloon payment as a percentage"
            value={form?.balloonPayment}
            error={form?.messages?.balloonPayment}
            onChange={handleChange}
            type="number"
            min="0"
          />
        </FormRow>
        <Button
          label={summary ? 'Recalculate' : 'Calculate'}
          disabled={!form?.valid}
          onClick={handleSubmit}
        />
      </form>
      {summary && <Summary summary={summary} />}
    </article>
  );
};

export default VehicleCalculator;
