import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from './reportWebVitals';
import { sendToVercelAnalytics } from './vitals';

import SalaryCalculator from './views/salary-calculator/SalaryCalculator';
import MortgageCalculator from './views/mortgage-calculator/MortgageCalculator';
import VehicleCalculator from './views/vehicle-calculator/VehicleCalculator';
import PricingPlans from './views/pricing-plans/PricingPlans';
import InstantQuote from './views/instant-quote/InstantQuote';
import InstantQuoteForm from './views/instant-quote/InstantQuoteForm';

import './index.scss';

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <MortgageCalculator />
    </React.StrictMode>,
    document.getElementById('mortgage-calculator')
  );
});

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <SalaryCalculator />
    </React.StrictMode>,
    document.getElementById('salary-calculator')
  );
});

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <VehicleCalculator />
    </React.StrictMode>,
    document.getElementById('vehicle-calculator')
  );
});

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <PricingPlans />
    </React.StrictMode>,
    document.getElementById('pricing-plans')
  );
});

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <InstantQuote />
    </React.StrictMode>,
    document.getElementById('instant-quote')
  );
});

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <InstantQuote />
    </React.StrictMode>,
    document.getElementById('instant-quote-mobile')
  );
});

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <InstantQuoteForm />
    </React.StrictMode>,
    document.getElementById('instant-quote-form')
  );
});

document.addEventListener('DOMContentLoaded', function () {
  ReactDOM.render(
    <React.StrictMode>
      <InstantQuote />
    </React.StrictMode>,
    document.getElementById('instant-quote-specific')
  );
});

reportWebVitals(sendToVercelAnalytics);
