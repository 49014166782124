import React from 'react';

import { getClassNames } from '@neslotech/utils';

import './button.scss';

const Button = ({
  label,
  type = 'button',
  onClick,
  noMarginTop = false,
  disabled = false,
  large = false
}) => {
  return (
    <button
      className={getClassNames('button', { disabled, large, noMarginTop })}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {label}
    </button>
  );
};

export default Button;
