import React, { useMemo } from 'react';

import { currencyFormat } from '../../../tools/calculators';

import {
  limitedEmployees,
  limitedPriceBands,
  propertyEmployees,
  propertyPriceBands,
  soleTraderEmployees,
  soleTraderPriceBands
} from '../../pricing-plans/PricingPlans';
import PackageSummary from '../../pricing-plans/summary/PackageSummary';

import './package-modal.scss';

const PackageModal = ({ onSubmit, payload }) => {
  const priceBands = useMemo(() => {
    switch (payload?.companyType) {
      case 'property':
        return propertyPriceBands;
      case 'sole_trader':
        return soleTraderPriceBands;
      default:
        return limitedPriceBands;
    }
  }, [payload?.companyType]);

  const employeeLimits = useMemo(() => {
    switch (payload?.companyType) {
      case 'property':
        return propertyEmployees;
      case 'sole_trader':
        return soleTraderEmployees;
      default:
        return limitedEmployees;
    }
  }, [payload?.companyType]);

  const summary = useMemo(() => {
    const turnover = Number(payload.turnover);
    const prices = priceBands[turnover];

    return {
      employees: Number(payload.employees),
      employeeLimits,
      context: payload.companyType,
      base: currencyFormat(prices[0]),
      peak: currencyFormat(prices[1]),
      summit: currencyFormat(prices[2])
    };
  }, [payload, priceBands, employeeLimits]);

  const handleSubmit = (selectedPackage) => {
    onSubmit({
      ...payload,
      ...selectedPackage
    });
  };

  return (
    <article className="package-modal">
      <header>
        <p className="package-modal__message">
          Based on the information you have given us we recommend the package below
        </p>
        <p>**All prices exclude VAT**</p>
      </header>
      <main>
        <PackageSummary summary={summary} onSubmit={handleSubmit} />
      </main>
    </article>
  );
};

export default PackageModal;
