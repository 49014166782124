import React, { useState } from 'react';

import { capitalise, scrollIntoView } from '@neslotech/utils';

import { sendEmail } from '../../tools/mailer';

import Modal from '../../components/modal/Modal';
import ModalLayout from '../../components/modal/ModalLayout';
import InstantQuoteModal from './modal/InstantQuoteModal';
import PackageModal from './modal/PackageModal';
import QuoteModal from '../pricing-plans/modal/QuoteModal';

const InstantQuoteForm = () => {
  const [showModal, setShowModal] = useState(false);
  const [showError, setShowError] = useState(false);
  const [payload, setPayload] = useState();
  const [step, setStep] = useState(1);

  const handleQuoteSubmit = (payload) => {
    setShowModal(true);
    setPayload(payload);
    setStep(2);
    scrollIntoView('pricing-heading');
  };

  const handlePackageSubmit = (payload) => {
    setPayload(payload);
    setStep(3);
  };

  const handleFinalSubmit = (details) => {
    const finalPayload = { ...payload, ...details };
    setShowError(false);
    sendEmail(
      [
        {
          name: 'Xenith Wealth',
          email: 'info@xenithwealth.co.uk'
        }
      ],
      { name: finalPayload.fullName ?? finalPayload.business, email: finalPayload.email },
      `
            <html>
        <body>
        <header>
            <h3>Hi Xenith Wealth!</h3>
        </header>
        <main>
            <p>An instant quote has been requested with the following details:</p>
            <br /><br />
            <strong>Full Name: </strong>
            <p>${finalPayload.fullName}</p><br />
            <strong>Business Name: </strong>
            <p>${finalPayload.business}</p><br />
            <strong>Contact Number: </strong>
            <p>${finalPayload.cellphone}</p><br />
            <strong>Email Address: </strong>
            <p>${finalPayload.email}</p><br />
            <strong>Turnover Range: </strong>
            <p>${finalPayload.turnoverAmount}</p><br />
            <strong>Selected Package: </strong>
            <p>${finalPayload.type}</p><br />
            <strong>Package Cost: </strong>
            <p>${finalPayload.cost}</p><br />
            <strong>Company Type: </strong>
            <p>${capitalise(finalPayload.companyType)}</p><br />
            <strong>Needs Bookkeeping: </strong>
            <p>${finalPayload.bookkeeping ? 'Yes' : 'No'}</p><br />
            <strong>Needs VAT Returns: </strong>
            <p>${finalPayload.vatReturns ? 'Yes' : 'No'}</p><br />
            <strong>Needs Payroll: </strong>
            <p>${finalPayload.payroll ? 'Yes' : 'No'}</p><br />
            <strong>Number of Employees: </strong>
            <p>${finalPayload.payroll ? finalPayload.employees : 'N/A'}</p><br />
        </main>
        </body>
        </html>
            `,
      'Instant Quote Requested | Xenith Wealth Website'
    )
      .then(() => {
        setShowModal(false);
      })
      .catch((error) => {
        setShowError(true);
      });
  };

  return (
    <>
      <InstantQuoteModal onSubmit={handleQuoteSubmit} />
      {showModal && (
        <Modal>
          <ModalLayout title="Get An Instant Quote" onClose={() => setShowModal(false)} large>
            {step === 2 && <PackageModal onSubmit={handlePackageSubmit} payload={payload} />}
            {step === 3 && <QuoteModal onSubmit={handleFinalSubmit} error={showError} />}
          </ModalLayout>
        </Modal>
      )}
    </>
  );
};

export default InstantQuoteForm;
