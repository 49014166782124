import React from 'react';

import './summary.scss';

const Summary = ({ summary }) => (
  <article className="summary">
    {summary.employer && (
      <>
        <dl>
          <dt>Monthly employee net income:</dt>
          <dd>{summary.monthlySalary}</dd>
        </dl>
        <dl>
          <dt>Monthly income tax:</dt>
          <dd>{summary.monthlyTaxDue}</dd>
        </dl>
        <dl>
          <dt>Monthly National Insurance:</dt>
          <dd>{summary.monthlyEmployerInsuranceDue}</dd>
        </dl>
        <dl>
          <dt>Total cost to company:</dt>
          <dd>{summary.costToCompany}</dd>
        </dl>
      </>
    )}
    {!summary.employer && (
      <>
        <dl>
          <dt>Annual gross income:</dt>
          <dd>{summary.annualSalary}</dd>
        </dl>
        <dl>
          <dt>Monthly income tax:</dt>
          <dd>{summary.monthlyTaxDue}</dd>
        </dl>
        <dl>
          <dt>Monthly National Insurance:</dt>
          <dd>{summary.monthlyInsuranceDue}</dd>
        </dl>
        <dl>
          <dt>Your take home pay:</dt>
          <dd>{summary.takeHomePay}</dd>
        </dl>
      </>
    )}
  </article>
);

export default Summary;
